/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  min-height: 100vh;
  background: #e2e2e2;
}

/* Without this classes we have wrong borders for mat-table */
.x-header-row, .x-row {
  border-bottom: 1px solid rgba(0,0,0,.12);
  align-items: center;
  min-height: 48px;
  padding: 0 24px;
}
